@import "../../styles/theme.scss";

.SearchBox {
  &__inputAndResultContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    text-align: center;

    @media (max-width: $small) {
      width: 100%;
    }
  }

  &__inputContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__inputIconContainer {
    position: relative;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $medium) {
      width: 100%;
    }
  }

  &__iconContainer {
    position: absolute;
    right: 21px;
    top: calc(50% - 26px);
    height: 52px;
    width: 52px;
    cursor: pointer;

    @media (max-width: $small) {
      // display: none;
      height: 26.5px;
      width: 26.5px;
      right: 30px;
    }

    &:hover {
      svg {
        path {
          stroke: white;
        }

        circle {
          stroke: white;
        }
      }
      border-radius: 50%;
      background-color: $tiicker-green;
    }
  }

  &__input {
    height: 94px;
    width: 100%;
    flex-grow: 1;
    font-size: 32px;
    line-height: 1.75;
    border: solid 2.5px $tiicker-green;
    padding-left: 34px;
    color: $secondary;

    &::placeholder {
      color: $secondary;
      opacity: 1;
    }

    @media (max-width: $small) {
      &::placeholder {
        font-size: 17px;
      }
    }

    &:focus-visible {
      outline: none !important;
      box-shadow: none !important;
    }

    @media (max-width: $medium) {
      font-size: 26px;
      width: 100%;
    }

    @media (max-width: $small) {
      font-size: 20px;
      height: 64px;
      padding-left: 20px;
    }
  }
}

.inlineInputContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.popBrandsContainer {
  margin-top: 32px;
  max-width: 100%;
  overflow: scroll;
}

.icon {
  @media (max-width: $small) {
    transform: scale(0.55);
  }
}

.showTillSmall {
  @media (max-width: $small) {
    display: none;
  }
}

.hideTillSmall {
  display: none;

  @media (max-width: $small) {
    display: unset;
  }
}
