@import "../../styles/theme.scss";

.SearchResults {
  position: relative;
  width: 100%;

  &__container {
    position: absolute;
    text-align: left;
    padding: 32px 24px 30px;
    box-shadow: 0 15px 30px 0 rgba(9, 28, 55, 0.1);
    margin-right: 128px;
    background-color: white;
    z-index: 2;

    @media (max-width: $small) {
      margin-right: 0;
    }
  }

  &__row {
    padding: 8px 0;

    color: $navy;

    &:hover {
      background-color: $background;
      cursor: pointer;
    }
  }
}
