@import "../../../styles/theme.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  a {
    color: $body;
  }

  :nth-child(n + 8) {
    display: none;
  }

  @media (max-width: $medium) {
    // :nth-child(n + 6) {
    //   display: none;
    // }
  }

  @media (max-width: $small) {
    // :nth-child(n + 5) {
    //   display: none;
    // }
    justify-content: left;
    overflow: scroll;
    width: 100%;
  }

  @media (max-width: $xsmall) {
    // :nth-child(n + 4) {
    //   display: none;
    // }
    overflow: scroll;
    width: 100%;
  }
}

.popular {
  font-size: 16px;
  line-height: 1.75;
  opacity: 0.7;
  margin-right: 16px;
  margin-bottom: 0;
}

.brandName {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 16px;
  background-color: $pale-mint;
  height: 100%;
  margin-right: 16px;

  font-size: 18px;
  line-height: 1.2;

  &:hover {
    cursor: pointer;
    background-color: $green-highlight;
    color: $navy;
  }
}
